$browser-context: 16; // Default

// down
$desktop-lg-down-max: 1919.98px;
$desktop-lg-down: 1439.98px;
$desktop-down: 1199.98px;
$desktop-sm-down: 991.98px;

$tablets-lg-down: 899.98px;
$tablets-down: 767.98px;
$tablets-sm-down: 599.98px;

$phones-lg-down: 575.98px;
$phones-down: 374.98px;
$phones-sm-down: 319.98px;

// up
$desktop-lg-up-max: 1920px;
$desktop-lg-up: 1440px;
$desktop-up: 1200px;
$desktop-sm-up: 992px;

$tablets-lg-up: 900px;
$tablets-up: 768px;
$tablets-sm-up: 600px;

$phones-lg-up: 576px;
$phones-up: 375px;
$phones-sm-up: 320px;

$header-h: 140;
$header-h-scrolled: 110;
$header-h-m: 60;

//colors
$white: #ffffff;
$black: #000000;
$red: #FF2F44;
$error: #E03C25;

// durations
$tr-duration: 0.3s;
$tr-hover: 0.3s;

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@function lh($lh, $fs) {
  @return #{$lh/$fs};
}

@mixin bg($url) {
  background: url($url) no-repeat 50%/100%;
}

@mixin fz($fz, $lh: null) {
  font-size: rem($fz);
  @if ($lh) {
    line-height: lh($lh, $fz);
  }
}

@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
    object-fit: $fit;
  @if $position {
    -o-object-position: $position;
      object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin text_clamp($mxHeight, $rows) {
  max-height: rem($mxHeight);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: $rows;
}

@function str-replace($string, $search, $replace: '') {
  $string: quote($string);
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return unquote($string);
}


// IE10+ CSS styles go here
@mixin target-ie11 {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin target-edge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin target-safari {
  @media not all and (min-resolution: .001dpcm) {
    @content;
  }
}

@mixin text_gardient($gradient, $fallback: null) {
  background: linear-gradient(unquote($gradient));
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @if ($fallback) {
    @include target-ie11{
      color: $fallback;
      background: transparent;
    }
  }
}

//-------------- media --------------
@mixin desktop-lg-down {
  @media screen and (max-width: $desktop-lg-down) {
    @content;
  }
}

@mixin desktop-lg-up {
  @media screen and (min-width: $desktop-lg-up) {
    @content;
  }
}



@mixin desktop-down {
  @media screen and (max-width: $desktop-down) {
    @content;
  }
}

@mixin desktop-up {
  @media screen and (min-width: $desktop-up) {
    @content;
  }
}



@mixin desktop-sm-down {
  @media screen and (max-width: $desktop-sm-down) {
    @content;
  }
}

@mixin desktop-sm-up {
  @media screen and (min-width: $desktop-sm-up) {
    @content;
  }
}



@mixin tablets-lg-down {
  @media screen and (max-width: $tablets-lg-down) {
    @content;
  }
}

@mixin tablets-lg-up {
  @media screen and (min-width: $tablets-lg-up) {
    @content;
  }
}



@mixin tablets-down {
  @media screen and (max-width: $tablets-down) {
    @content;
  }
}

@mixin tablets-up {
  @media screen and (min-width: $tablets-up) {
    @content;
  }
}



@mixin tablets-sm-down {
  @media screen and (max-width: $tablets-sm-down) {
    @content;
  }
}

@mixin tablets-sm-up {
  @media screen and (min-width: $tablets-sm-up) {
    @content;
  }
}



@mixin phones-lg-down {
  @media screen and (max-width: $phones-lg-down) {
    @content;
  }
}

@mixin phones-lg-up {
  @media screen and (min-width: $phones-lg-up) {
    @content;
  }
}



@mixin phones-down {
  @media screen and (max-width: $phones-down) {
    @content;
  }
}

@mixin phones-up {
  @media screen and (min-width: $phones-up) {
    @content;
  }
}

@mixin phones-sm-down {
  @media screen and (max-width: $phones-sm-down) {
    @content;
  }
}

@mixin phones-sm-up {
  @media screen and (min-width: $phones-sm-up) {
    @content;
  }
}

@mixin custom-down($prop) {
  @media screen and (max-width: $prop) {
    @content;
  }
}

@mixin custom-up($prop) {
  @media screen and (min-width: $prop) {
    @content;
  }
}