@import "_media.scss";

* {
	box-sizing: border-box;
}

html {
	font-size: 16px;

	@include custom-down(1919.98px) {
		font-size: 13px;
	}

	@include desktop-lg-down() {
		font-size: 12px;
	}

	@include desktop-sm-down() {
		font-size: 11px;
	}

	@include tablets-lg-down() {
		font-size: 10px;
	}

	@include tablets-down() {
		font-size: 16px;
	}

	@include phones-down {
		font-size: 15px;
	}
}

p {
	margin: 0;
}

ol, ul {
	margin: 0;
	padding: 0;
}

button {
	cursor: pointer;
}

html,
body {
  max-width: 100%;
	height: 100%;
}

body {
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

ul, li {
	list-style: none;
	margin: 0;
	padding: 0;
}

img {
	display: block;
}

a {
	text-decoration: none;
}

// footer down start
.header,
.footer {
  flex: 0 0 auto;
}

.main {
  flex: 1 0 auto;
	position: relative;
	overflow: hidden;
}

.wrapper {
	height: 100%;
  display: flex;
	flex-direction: column;
	position: relative;
}
// footer down end

.container {
	width: 100%;
	max-width: rem(1440);
	padding: 0 rem(20);
	margin: 0 auto;
}
