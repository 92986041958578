* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

@media screen and (max-width: 1919.98px) {
  html {
    font-size: 13px;
  }
}

@media screen and (max-width: 1439.98px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 991.98px) {
  html {
    font-size: 11px;
  }
}

@media screen and (max-width: 899.98px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-width: 767.98px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 374.98px) {
  html {
    font-size: 15px;
  }
}

p {
  margin: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

html,
body {
  max-width: 100%;
  height: 100%;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  display: block;
}

a {
  text-decoration: none;
}

.header,
.footer {
  flex: 0 0 auto;
}

.main {
  flex: 1 0 auto;
  position: relative;
  overflow: hidden;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.container {
  width: 100%;
  max-width: 90rem;
  padding: 0 1.25rem;
  margin: 0 auto;
}
